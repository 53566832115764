<template>
	<div id="change-log" class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 40px;">
			<div>
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder">
						Change Log
					</span>
				</h3>
			</div>
			<div class="card-toolbar">
				<div class="search-box" style="margin-right: 15px;">
					<b-form-input
						id="filter-input"
						class="search-bar"
						size="sm"
						v-model="searchText"
						type="search"
						placeholder="Search"
						debounce="500"
					/>
					<b-icon-search class="search-icon" />
				</div>
			</div>
		</div>
		<div class="card-body pt-2 pb-0" style="max-height: 600px; overflow-y: scroll;">
			<ITCSpinner :loaded="loaded">
				<b-table
					:items="activity"
					:fields="[
                    { key:'timestamp', label: 'Time'}, 
                    'username',
                    { key:'ip', label: 'IP'}, 
                    'table',
                    { key:'oldval', label: 'Old Value', class: 'text-wrap', tdClass: 'col-sets'}, 
                    { key:'newval', label: 'New Value', class: 'text-wrap', tdClass: 'col-sets'}, 

                    ]"
					:current-page="currentPage"
					:filter="searchText"
					show-empty
                    outlined striped hover
				>
                    <template #cell(ip)="data">
						 {{ data.value }}
					</template>

				</b-table>
			</ITCSpinner>
		</div>
		<div class="footer">
            <span>{{activity.length}} results</span>
            <span @click.prevent="pageChange">
			<b-pagination
				class="pagination"
				v-model="currentPage"
				:total-rows="rows"
				per-page="10"
			/>
            </span>
		</div>
	</div>
</template>

<script>
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';

export default {
	name: 'ChangeLog',
	data() {
		return {
			activity: [],
			searchText: '',
			currentPage: 1,
            loaded: false,
            itemsPerPage: 10,
            predicate: 'timestamp',
            reverse: false,
		};
	},
	components: {
		ITCSpinner,
	},
	watch: {
		searchText() {
			this.getLog();			
		}
	},
	computed: {
      rows() {
		if(this.searchText==='') {
        	return 10000;
		} else {
			return this.activity.length;
		}
      }
    },
    methods: {
        getLog() {
            this.loaded = false;

                let srch = '';
                if(this.searchText) {
                    srch = `/${this.searchText}`;
                }

            this.$http.get(`activitylog/${this.currentPage}/${this.itemsPerPage}/${this.predicate}/${this.reverse}${srch}`)
                .then(res => {
                    this.activity = res.data.data.data;
                    this.loaded = true;
                });
        },
        pageChange() {
            this.getLog();
        }
    },
    mounted() {
        this.getLog();
    },
	created() {

	},
};
</script>

<style scoped>
.search-box {
	position: relative;
}
.search-bar {
	padding-left: 30px;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.footer {
	padding: 15px 30px 0px;
}
.pagination {
	float: right;
}
.col-sets {
	max-width:700px;
}
</style>
